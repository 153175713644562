import { IsOptional, IsNumber } from 'class-validator'

import { ValidateDTO } from '..'

import type { FacilityDetails } from '../../../@types/BasicFacility'

import { BASIC_FACILITIES_CONTROLLER } from './controller.common-vars'

import { BASIC_FACILITY_DATA_STATUS } from '@/imports/@enums/basic-facilities.enum'

export const BASIC_FACILITY_DETAIL_SUBPATH = ':facilityId'
export const BASIC_FACILITY_DETAIL_URI = `${BASIC_FACILITIES_CONTROLLER}/${BASIC_FACILITY_DETAIL_SUBPATH}`

export class BasicFacilityDetailDTOReq extends ValidateDTO {
  @IsNumber()
  @IsOptional()
  pageNo?: number

  @IsNumber()
  @IsOptional()
  perPage?: number

  @IsNumber()
  @IsOptional()
  year?: number
}

type OperationsSubActivityData = {
  subActivityName: string
  subActivityId: number
  quantity: number
  unit: string
}

type OperationsActivityData = {
  activityName: string
  activityId: number
  subActivities: OperationsSubActivityData[]
}

type OperationsData = {
  status: BASIC_FACILITY_DATA_STATUS
  year: number
  totalProductQuantity: number
  activities: OperationsActivityData[]
}

export type BasicFacilityDetailDTORes = {
  facility: FacilityDetails
  operationsData: OperationsData[]
}
