import type { AxiosInstance } from 'axios'

import {
  FILE_UPLOAD_ERROR_RESPONSE_URI,
  type FileIngestionErrorResponse,
} from '@lib/DTO/file-upload/file-error-response.dto'

import type { DeletionRules, MaintenanceErrors } from '../../DTO/self-serve/deletable-self-serve.dto'

import { replaceUriParams } from '../../DTO'

import { FILE_UPLOAD_DELETABLE_URI } from '../../DTO/file-upload/deletable-file-upload.dto'
import {
  FILE_UPLOAD_REQUEST_DELETE_URI,
  type FileUploadRequestDeleteDTORes,
} from '../../DTO/file-upload/request-delete-file-upload.dto'

import { FILE_UPLOAD_DELETE_URI } from '../../DTO/file-upload/delete-file-upload.dto'

import type { ApiResponseInner } from '@/imports/@types/ApiResponse'
import type { AqFile } from '@/imports/@types/AqFile'
import type { Paginated } from '@/imports/@types/Paginated'

export class FileUploadsApi {
  private http: AxiosInstance

  constructor(http: AxiosInstance) {
    this.http = http
  }

  getProductFileUploads({
    orgId,
    dataSourceId,
    pageNo,
    perPage,
    sort,
    sortField,
  }: {
    orgId: string
    dataSourceId: string
    pageNo: number
    perPage: number
    sort: { [key: string]: string } | null
    sortField: string
  }) {
    return this.http.post<ApiResponseInner<Paginated<AqFile>>>('/fileuploads/findbyfiletype', {
      orgId,
      fileType: 'dataSource',
      pageNo,
      perPage,
      sort,
      sortField,
      metaQuery: {
        dataSourceType: 'products',
        dataSourceId,
      },
    })
  }

  getCanDeleteCheck(fileUploadId: string, orgId: string) {
    const requestURI = replaceUriParams(FILE_UPLOAD_DELETABLE_URI, { fileUploadId })

    return this.http.get<{ maintenanceErrors: MaintenanceErrors | undefined; deletionRules: DeletionRules }>(
      requestURI,
      {
        params: {
          orgId,
        },
      },
    )
  }

  getFilesErrorsSummary(dataSourceId: string) {
    return this.http.get<{ errorSummary: FileIngestionErrorResponse }>(
      replaceUriParams(FILE_UPLOAD_ERROR_RESPONSE_URI, { dataSourceId }),
    )
  }

  deleteFile(fileUploadId: string, orgId: string) {
    const requestURI = replaceUriParams(FILE_UPLOAD_DELETE_URI, { fileUploadId })

    return this.http.delete(requestURI, {
      params: { fileUploadId },
      headers: {
        'x-org-id': orgId,
      },
    })
  }

  requestDeleteFileUpload(orgId: string, fileUploadId: string, isUserPlanningToModify: boolean) {
    const requestURI = replaceUriParams(FILE_UPLOAD_REQUEST_DELETE_URI, {
      fileUploadId,
      isUserPlanningToModify,
    })

    return this.http.patch<ApiResponseInner<FileUploadRequestDeleteDTORes>>(requestURI, {
      fileUploadId,
      orgId,
      isUserPlanningToModify,
    })
  }
}
