<template>
  <svg
    :class="['chevron', direction]"
    :width="calculatedWidth"
    :height="calculatedHeight"
    viewBox="0 0 9 12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.839966 1.41L5.41997 6L0.839966 10.59L2.24997 12L8.24997 6L2.24997 0L0.839966 1.41Z"
      fill="#1C2121"
    />
  </svg>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'Chevron',

    props: {
      width: {
        type: [String, Number],
        default: 9,
      },

      direction: {
        type: String,
        default: 'right',
        validator: (direction: string) => ['up', 'right', 'down', 'left'].includes(direction),
      },
    },

    computed: {
      /**
       * If the chevron is pointing left or right use the width
       * passed in the prop. If the chevron is pointing up or down
       * return null and instead use the width prop to define the
       * height of the svg
       */
      calculatedWidth() {
        if (['left', 'right', 'down', 'up'].includes(this.direction)) return this.width
      },

      calculatedHeight() {
        if (!['left', 'right', 'down', 'up'].includes(this.direction)) return this.width
      },
    },
  })
</script>

<style lang="scss" scoped>
  $module: 'chevron';

  .#{$module} {
    transform-origin: center;
    transition: transform 0.05s linear;

    &.left {
      transform: rotate(180deg);
    }

    &.up {
      transform: rotate(-90deg);
    }

    &.down {
      transform: rotate(90deg);
    }
  }
</style>
